footer {
	background: $blue-dark;
	padding: 40px 0;

	div[class^="col-"] {
		&:first-child {
			padding-right: 0;
		}

		&:last-child {
			padding-left: 0;
		}
	}

	.trademark {
		color: white;
		font-size: 1rem;
		margin: 0;
		text-transform: initial;
    @media (max-width: $screen-xs-max) {
      text-align: center;
    }
	}

	.footer-socials {
		display: inline-block;
		vertical-align: middle;

		@media (max-width: $screen-sm-max) {
			display: block;
			text-align: center;
			margin-bottom: 15px;
		}
	}

	.follow-us {
		display: none;
		color: white;
		text-transform: uppercase;

		@media (max-width: $screen-xs-max) {
			display: block;
		}
	}

	.social {
		color: white;
		font-size: 2.5rem;
		margin: 0 1px;

		@media (max-width: $screen-md-max) {
			font-size: 2rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 4rem;
			margin: 5px;
		}

		&:hover {
			color: $yellow;
		}
	}

	.footer-info {
		width: 50%;
		display: inline-block;
		vertical-align: middle;
		text-align: right;

		@media (max-width: $screen-md-max) {
			width: 55%;
		}

		@media (max-width: $screen-sm-max) {
			width: 65%;
		}

		@media (max-width: $screen-sm-max) {
			display: block;
			width: 100%;
			text-align: center;
		}
	}

	.link {
		color: $yellow;
		font-weight: bold;
		margin: 0 13px;
		font-family: $font-bold;
		border-bottom: 1px solid transparent;

		@media (max-width: $screen-md-max) {
			margin: 0 10px;
		}

		@media (max-width: $screen-sm-max) {
			margin: 0 15px;
		}

		@media (max-width: $screen-xs-max) {
			margin: 0 6px;
			font-size: 1.3rem;
		}

		&:hover {
			text-decoration: none;
			border-bottom: 1px solid $yellow;
		}
	}

	.footer-phone {
		white-space: nowrap;
	}

  .footer-legal {
    padding-top: 3rem;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
    }
  }
}


body {
	font-family: $font-reg;
	font-weight: inherit;
	background: white;
	position: relative;
	// font-size: 62.5%; /* font-size 1em = 10px on default browser settings */
	color: $color-primary;
	font-size: 1.8rem;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

	&.no-scroll {
		overflow: hidden;
	}
}

.wrap {
	position: relative;
	padding-top: 95px;
}

.home {
	.wrap {
		padding-top: 0;
	}
}

h1,
.title {
	font-size: 3.5rem;
	font-family: $font-light;
	font-weight: inherit;
	line-height: 1.4em;
	margin-bottom: 20px;
	color: $color-primary;
	text-transform: uppercase;

	@media (max-width: $screen-md-max) {
		font-size: 3.2rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 2.9rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 2.6rem;
	}

	// -webkit-font-smoothing: antialiased;
 //  -webkit-text-stroke-width: 0.2px;
 //  -moz-osx-font-smoothing: grayscale;
		
	&:first-child {
		margin-top: 0;

		@media (max-width: $screen-xs-max) {
			margin-top: 30px;
		}
	}

	&.title-white {
		color: white;
	}
}

h2,
.subtitle {
	font-size: 3rem;
	line-height: 1.4em;
	font-family: $font-reg;
	font-weight: inherit;
	margin: 40px 0 20px;
	color: $blue;
	text-transform: uppercase;

	@media (max-width: $screen-md-max) {
		font-size: 2.2rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 2rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 1.8rem;
	}
}

h3 {
	font-size: 2.4rem;
	line-height: 1.3em;
	font-family: $font-reg;
	font-weight: inherit;
	margin-top: 30px;
	text-transform: uppercase;

	@media (max-width: $screen-md-max) {
		font-size: 2.2rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 2rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 1.8rem;
	}
}

h4,h5,h6 {
	text-transform: uppercase;
}

h5 {
	font-size: 1.7rem;
	line-height: 1.3em;
	font-family: $font-bold;
	margin-top: 30px;
}

a, p, li, td, th, .text, .text p {
	font-size: 1.7rem;
	line-height: 1.3em;
	color: $color-primary;

	@media (max-width: $screen-md-max) {
		font-size: 1.6rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 1.5rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 1.4rem;
	}
}

strong {
	font-family: $font-bold;
	font-weight: inherit;
}

blockquote {
	font-size: 2.4rem;

	@media (max-width: $screen-md-max) {
		font-size: 2.2rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 2rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 1.8rem;
	}
}

ul {
	margin-bottom: 0;
}

ul > li {
	list-style: square;
}

ul > li > ul > li {
	list-style: circle;
}

a {
	transition: all 0.2s ease-in-out;

	&:focus {
	  color: $grey-dark;
	  text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 6px solid $blue;
  margin: 20px auto;
  padding: 0; 
  max-width: 130px;
}

iframe {
	border: 0;
}

.video-wrapper {
	margin-top: 30px;
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	// padding-top: 25px;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

.clearfix:after {
     visibility: hidden;
     display: block;
     font-size: 0;
     content: " ";
     clear: both;
     height: 0;
     }
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */


@media (max-width: $screen-xs-max) {
	.affix {
    position: static;
  }
}

table,
.table {
	margin: 30px 0;

	& > tbody {
		& > tr {
			border-bottom: 1px solid $grey-light;

			&:first-child {
				border-top: 0;
				border-bottom: 3px solid $grey-light;

				td, th {
					font-size: 1.8rem;
					font-weight: bold;
					padding: 10px;
				}
			}

			& > th,
			& > td {
				border: 0;
				padding: 8px;
				font-size: 1.8rem;

				@media (max-width: $screen-md-max) {
					font-size: 1.7rem;
				}

				@media (max-width: $screen-sm-max) {
					font-size: 1.6rem;
				}

				@media (max-width: $screen-xs-max) {
					font-size: 1.5rem;
				}
			}
		}
	}

	&.vertical {
		tr {
			border-bottom: 0;

			&:first-child {
				border-bottom: 0;

				td {
					border-bottom: 0;
					border-left: 3px solid $grey-light;
				}
			}
		}

		td {
			border-left: 1px solid $grey-light;
			border-bottom: 0;
			border-top: 0;
		}
	}

	th.dates {
		@media (min-width: $screen-sm-min) {
			min-width: 200px;
		}
	}
}

.table-responsive {
	@media (max-width: $screen-xs-max) {
		border: 0;
	}
}

@keyframes bounce {
  0% { width: 0; }
  100% { width: 100%; }
}

sup {
	font-size: 50%;
	top: -1em;
}









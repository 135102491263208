.single-post {
	@include section-padding;

	@media (max-width: $screen-xs-max) {
		padding-top: 0;
	}

	h3 {
		margin-bottom: 30px;
	}

	.title {
		margin-top: 0;
	}

	.blog-cat {
		font-family: $font-bold;
		font-size: 1.5rem;
		text-transform: uppercase;
		background: $yellow;
		display: block;
		padding: 5px 5px 5px 20px;
		margin-bottom: 0;

		@media (max-width: $screen-md-max) {
			font-size: 1.4rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.3rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1rem;
			margin-bottom: 0;
		}

		&.diy {
			background: $color-sec;
			color: white;
		}
	}

	.blog-image {
		width: 100%;
		height: 400px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		margin-bottom: 20px;

		@media (max-width: $screen-md-max) {
			height: 350px;
		}

		@media (max-width: $screen-sm-max) {
			height: 300px;
		}

		@media (max-width: $screen-xs-max) {
			height: 200px;
		}
	}

	.entry-title {
		font-family: $font-bold;
		font-size: 4rem;
		line-height: 1.2em;
		text-transform: initial;

		@media (max-width: $screen-md-max) {
			font-size: 3.5rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 3rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 2.5rem;
		}
	}

	.entry-meta {
		text-align: center;

		.updated {
			font-size: 1.5rem;
			color: $color-sec;
		}
	}

	strong {
		font-weight: 600;
	}

	.entry-image,
	figure {
		margin: 40px 0;

		@media (max-width: $screen-md-max) {
			margin: 30px 0;
		}

		@media (max-width: $screen-sm-max) {
			margin: 25px 0;
		}

		@media (max-width: $screen-xs-max) {
			margin: 20px 0;
		}
	}

	.entry-content {
		margin: 40px 0 60px;

		@media (max-width: $screen-md-max) {
			margin: 30px 0 40px;
		}

		@media (max-width: $screen-sm-max) {
			margin: 25px 0 30px;
		}

		@media (max-width: $screen-xs-max) {
			margin: 20px 0;
		}
	}

	.entry-date,
	.entry-author {
		display: inline-block;
		font-size: 1.7rem;
		line-height: 1em;

		@media (max-width: $screen-md-max) {
			font-size: 1.5rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.4rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1.3rem;
		}
	}

	// .entry-date {
	// 	border-right: 2px solid $color-primary;
	// 	margin-right: 15px;
	// 	padding-right: 15px;
	// }

	blockquote {
		color: $color-sec;
		margin: 30px 40px;
		padding-left: 20px;
		border-left: 4px solid $grey-light;

		@media (max-width: $screen-md-max) {
			margin: 20px 30px;
			padding-left: 16px;
			border-left: 3px solid $grey-light;
		}

		@media (max-width: $screen-sm-max) {
			margin: 15px 25px;
			padding-left: 14px;
		}

		@media (max-width: $screen-xs-max) {
			margin: 10px 20px;
			padding-left: 12px;
			border-left: 2px solid $grey-light;
		}

		p {
			font-size: 2.4rem;

			@media (max-width: $screen-md-max) {
				font-size: 2rem;
			}

			@media (max-width: $screen-sm-max) {
				font-size: 1.8rem;
			}

			@media (max-width: $screen-xs-max) {
				font-size: 1.6rem;
			}
		}

		& + h5 {
			font-weight: 300;
			margin-bottom: 50px;
		}
	}

	img {
		&.size-full {
			margin: 40px 0;

			@media (max-width: $screen-md-max) {
				margin: 30px 0;
			}

			@media (max-width: $screen-sm-max) {
				margin: 25px 0;
			}

			@media (max-width: $screen-xs-max) {
				margin: 20px 0;
			}
		}

		&.alignleft {
			margin: 0 30px 20px 0;

			@media (max-width: $screen-md-max) {
				margin: 0 24px 16px 0;
			}

			@media (max-width: $screen-sm-max) {
				width: 100%;
				margin: 25px 0;
			}

			@media (max-width: $screen-xs-max) {
				margin: 20px 0;
			}
		}

		&.alignright {
			margin: 20px 0 20px 30px;

			@media (max-width: $screen-md-max) {
				margin: 16px 0 16px 24px;
			}

			@media (max-width: $screen-sm-max) {
				width: 100%;
				margin: 25px 0;
			}

			@media (max-width: $screen-xs-max) {
				margin: 20px 0;
			}
		}
	}

	.entry-tools {
		position: relative;
	}

	.entry-nav {
		width: 100%;

		ul {
			font-size: 0;
		}

		li {
			width: 50%;
			display: inline-block;
		}

		a {
			text-transform: uppercase;
			position: relative;

			&:hover {
				text-decoration: none;
				color: $color-primary;
			}
		}

		.next,
		.prev {

			a {
				color: $color-sec;

				&:after {
					font-family: 'FontAwesome';
					font-size: 4rem;
					color: $color-sec;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);

					@media (max-width: $screen-md-max) {
						font-size: 3rem;
					}

					@media (max-width: $screen-sm-max) {
						font-size: 2.5rem;
					}

					@media (max-width: $screen-xs-max) {
						font-size: 2rem;
					}
				}
			}
		}

		.next {
			text-align: right;

			a {
				padding-right: 30px;

				&:after {
					content: '\f105';
					right: 0;
				}
			}
		}

		.prev {
			a {
				padding-left: 30px;

				&:after {
					content: '\f104';
					left: 0;
				}
			}
		}
	}

	.share-btns {
		a {
			margin: 5px 0;
		}

		.at-share-btn {
			background: transparent !important;
		}

		svg {
			fill: $color-primary!important;
		}
	}

	aside {
		.blog-cat-title {
			margin-top: 33px;
		}
	}
}


















nav {
	ul {
		margin: 0;
		padding: 0;
	}
}

.nav-sec-wrapper {
	position: absolute;
	top: 30px;
	right: 55px;
	z-index: 7;
}

.nav-sec {
	display: inline-block;
	vertical-align: middle;

	&.is-active {
		li {
			&.lang-nav {
				a {
					@media (max-width: $screen-sm-max) {
						color: $color-primary;
					}
				}
			}
		}
	}

	li {
		list-style-type: none;
		margin: 0 25px;

		@media (min-width: $screen-md-min) {
			display: inline-block;
		}

		// @media (max-width: $screen-md-max) {
		// 	margin: 0 5px;
		// }

		@media (max-width: $screen-md-max) {
			display: none;
		}

		@media (max-width: $screen-xs-max) {
			border-top: 2px solid rgba(0,0,0,0.1);
			margin: 0 15px;
		}

		&.wpml-ls-item {
			@media (max-width: $screen-sm-max) {
				display: block;
				border-top: 0;
			}

			a {
				@media (max-width: $screen-xs-max) {
					color: white;
				}
			}
		}

		&.active,
		&.current-menu-item {
			a {
				font-family: $font-bold;
				border-bottom: 2px solid $color-primary;
			}
		}
	}

	a {
		padding: 0 0 2px;
		display: block;
		width: 100%;
		height: 100%;
		color: $color-primary;
		font-size: 1.6rem;
		line-height: 2rem;
		font-family: $font-medium;
		text-transform: uppercase;
		border-bottom: 2px solid transparent;

		@media (max-width: $screen-md-max) {
			padding: 0 0 2px;
			font-size: 1.3rem;
		}

		@media (max-width: $screen-sm-max) {
			padding: 0 0 2px;
		}

		@media (max-width: $screen-xs-max) {
			padding: 0 0 2px;
		}

		&:hover {
			text-decoration: none;
			border-bottom: 2px solid $color-primary;
			opacity: .8;
			background-color: transparent;
		}
	}
}

.nav-primary-bg {
	background: white;
	opacity: .95;
	width: 50%;
	height: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 6;
	display: none;

	@media (max-width: $screen-md-max) {
		width: 55%;
	}

	@media (max-width: $screen-sm-max) {
		width: 65%;
	}

	@media (max-width: $screen-xs-max) {
		width: 100%;
	}
}

.nav-primary-bg-page {
	z-index: 4;
}

.nav-primary-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 6;
	display: none;

	@media (max-width: $screen-xs-max) {
		right: 30px;
	}

	.nav-primary {
		margin-top: 120px;
		width: 300px;
		// max-width: 100%;
	}

	.menu {
		display: none;
	}

	li {
		list-style-type: none;
		display: block;
		text-align: right;
		margin: 40px 0;

		@media (max-width: $screen-md-max) {
			margin: 35px 0;
		}

		@media (max-width: $screen-sm-max) {
			margin: 30px 0;
		}

		@media (max-width: $screen-xs-max) {
			margin: 25px 0;
		}

		&.active,
		&.current_page_item {
			a {
				font-family: $font-medium;
			}
		}

		&.lang-nav {
			display: none;

			@media (max-width: $screen-xs-max) {
				display: block;
			}
		}
	}

	a {
		color: $color-primary;
		font-size: 3rem;
		font-family: $font-light;
		text-transform: uppercase;

		@media (max-width: $screen-md-max) {
			font-size: 2.8rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 2.6rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 2.4rem;
		}

		&:hover {
			text-decoration: none;
			opacity: .8;
		}
	}
}

.nav-footer {

	@media (min-width: $screen-md-min) {
		margin-top: 15px;
	}
	
	li {
		display: inline-block;
		margin: 0 7px;
	}

	a {
		font-size: 1.5rem;
		color: white;
		font-family: $font-reg;
		border-bottom: 1px solid transparent;

		&:hover {
			text-decoration: none;
			border-bottom: 1px solid white;
		}
	}
}



// http://callmenick.com/post/animating-css-only-hamburger-menu-icons
.nav-toggle {
  display: block;
  position: absolute;
	top: 20px;
	right: 0;
	z-index: 7;
	padding: 0 10px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 48px;
  height: 48px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;

  &:hover {
  	background: transparent;
  	opacity: .8;
  }

  &:focus {
	  outline: none;
	}

	span {
	  display: block;
	  position: absolute;
	  top: 22px;
	  left: 9px;
	  right: 9px;
	  height: 4px;
	  background: $color-primary;
	  transition: background 0s 0.3s;

	  @media (max-width: $screen-xs-max) {
			background: white;
		}

	  &::before,
	  &::after {
	  	position: absolute;
		  display: block;
		  left: 0;
		  width: 100%;
		  height: 4px;
		  background-color: $color-primary;
		  content: "";
		  transition-duration: 0.3s, 0.3s;
  		transition-delay: 0.3s, 0s;

  		@media (max-width: $screen-xs-max) {
				background: white;
			}
	  }

	  &::before {
		  top: -10px;
		  transition-property: top, transform;
		}

		&::after {
		  bottom: -10px;
		  transition-property: bottom, transform;
		}
	}

	&.is-active {

	  span {
		  background: none;

		  &::before {
			  top: 0;
			  transform: rotate(45deg);
			  transition-delay: 0s, 0.3s;

			  @media (max-width: $screen-xs-max) {
					background: $color-primary;
				}
			}

			&::after {
			  bottom: 0;
			  transform: rotate(-45deg);
			  transition-delay: 0s, 0.3s;

			  @media (max-width: $screen-xs-max) {
					background: $color-primary;
				}
			}
		}
	}
}



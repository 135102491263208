//@import url(https://fonts.googleapis.com/css?family=Lato:400,300,300italic,400italic,700,700italic);

// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$lime: 						#cadb01;
$green: 					#96c93d;
$yellow: 					#f8ec62;
$blue: 						#0064a6;
$blue-dark: 			#204392;
$red: 						#c01e2e;

$grey-lighter: 		#f7f5f5;
$grey-light: 			#b8b8b8;
$grey: 						#aaa9a9;
$grey-dark: 			#262626;

$color-primary:   $blue;
$color-sec:   		$green;

$black-10: 					rgba(0,0,0,0.1);
$black-20: 					rgba(0,0,0,0.2);
$black-30: 					rgba(0,0,0,0.3);

// Font family
$font-light:			'brandon_textlight', sans-serif;
$font-reg:				'brandon_text_regularregular', sans-serif;
$font-medium:			'brandon_textmedium', sans-serif;
$font-bold:				'brandon_textbold', sans-serif;
.lightbox {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 8;
	background: rgba(white,.9);
	overflow: auto;
	display: none;

	// &.lightbox-half {
	// 	width: 50%;
	// 	height: 50%;
	// 	top: 50%;
	// 	left: 50%;
	// 	transform: translate(-50%,-50%);
	// }

	.lightbox-content {
		margin: 70px auto;
		text-align: center;
		max-width: 1200px;

		@media (max-width: $screen-md-max) {
			margin: 50px;
		}

		@media (max-width: $screen-sm-max) {
			margin: 40px;
		}

		@media (max-width: $screen-xs-max) {
			margin: 30px;
		}

		&.disabled {
			opacity: .3;
			pointer-events: none;
		}
	}

	.container {
		// background: white;
		// box-shadow: 0 0 10px rgba(0,0,0,.8);
	}

	.close-button {
		position: fixed;
		top: 20px;
		right: 20px;
		z-index: 6;
		color: white;
		font-size: 2.2rem;
		background: black;
		border-radius: 50%;
		width: 36px;
		height: 36px;
		text-align: center;

		@media (max-width: $screen-md-max) {
			font-size: 2.1rem;
			width: 33px;
			height: 33px;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 2rem;
			width: 32px;
			height: 32px;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1.9rem;
			width: 30px;
			height: 30px;
		}

		.fa {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
		}
	}

	.container {
		width: 600px;
		max-width: 100%;
	}

	.loading-icon {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		display: none;

		.fa {
			font-size: 5rem;
		}
	}
}

















.moove-gdpr-info-bar-container {
  padding: 20px;
}

.moove-gdpr-cookie-notice {
  button { margin: 0; }

  .gdpr-legal-buttons {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    padding-top: 30px;
    @media (max-width: $screen-xs-max) { padding-top: 20px; padding-bottom: 14px; }

    br {
      display: none !important;
    }

    .gdpr-legal-button {
      font-size: 1.4rem;
      font-weight: 700;
      color: $color-primary;
      text-decoration: none !important;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }
}

.moove-gdpr-button-holder {
  width: 220px;
  @media (max-width: $screen-xs-max) { width: unset; }
}

// Grid system
// .main {
//   @include make-sm-column($main-sm-columns);
//   .sidebar-primary & {
//     @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
//   }
// }
// .sidebar {
//   @include make-sm-column($sidebar-sm-columns);
// }

.container-fluid {
	padding-left: 0;
	padding-right: 0;
}
// Search form
// .search-form {
//   @extend .form-inline;
// }
// .search-form label {
//   font-weight: normal;
//   @extend .form-group;
// }
// .search-form .search-field {
//   @extend .form-control;
// }
// .search-form .search-submit {
//   @extend .btn;
//   @extend .btn-default;
// }

form {
	text-align: left;

	& > .row {
		@media (min-width: $screen-sm-min) {
			margin-top: 10px;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	label {
		color: $color-primary;
		font-size: 1.6rem;
		line-height: 2rem;
		font-family: $font-light;
		margin: 0;

		@media (max-width: $screen-xs-max) {
			margin-top: 10px;
		}
	}

	.wpcf7-list-item {
		display: block;
	}

	textarea {
		resize: vertical;
	}

	.wpcf7-validation-errors {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 2rem;

		&:before {
			font-family: 'FontAwesome';
		  content: "\f06a";
		  color: $grey-dark;
		  margin-right: 5px;
		}
	}

	.wpcf7-response-output {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 1.2rem;
	}

	.wpcf7-mail-sent-ok {
		border: 0;
		padding: 20px;
		background: white;
		margin: 20px 0 0;
		font-size: 2rem;

		&:before {
			font-family: 'FontAwesome';
		  content: "\f058";
		  color: $grey-dark;
		  margin-right: 5px;
		}
	}

	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	input[type="tel"],
	select,
	textarea {
		width: 100%;
		max-width: 100%;
		color: $grey-dark;
		border: 0;
		padding: 5px 10px;
		outline: none;
		transition: all 0.2s ease-in-out;
		border-radius: 0;
		background: white;
		font-size: 1.6rem;
		line-height: 1.6rem;
		font-family: $font-light;
		height: 40px;

		@media (max-width: $screen-xs-max) {
			width: 100%;
		}

		&:focus {
			// border: 1px solid $color-primary;
			box-shadow: 1px 1px 1px $grey-light;
		}
	}

	input {
		@include placeholder {
		  color: $color-primary;
		}
	}

	input[type="date"],
	select {
		// padding: 16px 15px;
	}

	input[type="file"] {
		padding: 15px;
	}


	input[type="date"] {
		@media (max-width: $screen-sm-max) {
			min-height: 34px;
		}
	}

	textarea {
		width: 100%;
		height: 100px;
	}

	.form-date {
		position: relative;
		display: block;
		background: white;

		&:before {
			font-family: 'FontAwesome';
			content: '\f073';
			color: $grey-dark;
			font-size: 2rem;
			position: absolute;
			top: 1px;
			right: 5px;
			z-index: 4;
		}
	}

	

	p {
		margin: 5px 0 0;
	}

	.wpcf7-not-valid-tip {
		color: white;
		font-size: 1.2rem;
		background: $red;
		padding: 5px;
	  box-shadow: inset 0 1px 1px rgba(0,0,0,0.1);

		&:before {
			font-family: 'FontAwesome';
		  content: "\f06a";
		  color: white;
		  margin-right: 5px;
		}
	}

	&#loginform {
		input[type="text"],
		input[type="number"],
		input[type="email"],
		input[type="date"],
		input[type="search"],
		input[type="password"],
		select,
		textarea {
			background: $grey-light;
		}

		.login-remember {
			font-size: 2rem;
			margin-top: 10px;
		}
	}

}


/* IE fix */
select::-ms-expand {
    display: none;
}

/* Firefox fix */
// @-moz-document url-prefix() { 
// 	form {
// 		input[type="date"],
// 		select {
// 			padding: 11px 15px;
// 		}

// 		input[type="file"] {
// 			padding: 0 15px;
// 		}
// 	}
// }







input[type=radio] {
    display: none;
}

.wpcf7 input[type=radio] + .wpcf7-list-item-label:before {
  border-radius: 8px;
}

.wpcf7 input[type=radio]:checked + .wpcf7-list-item-label:before {
  content: "\2022";
  color: black;
  font-size: 2rem;
  text-align: center;
  line-height: 15px;
}





/*---------------------------------------------
** GRAVITY FORMS
---------------------------------------------*/

.gform_body {
	@media (max-width: $screen-xs-max) {
		margin-top: 40px;
	}

	ul {
		padding: 0;
	}

	li {
		list-style: none;
	}
}

.gfield {
	margin-bottom: 15px;
	line-height: 1.5rem;

	@media (max-width: $screen-xs-max) {
		margin: 0; 
	}
}

.gfield_html {
	margin: 0;
}

.gfield_description {
	font-style: italic;
	font-size: 1.3rem;
	line-height: 1.5rem;
}

.ginput_container {
	margin-top: 10px;
}

.gfield_radio,
.gfield_checkbox {
	input,
	label {
		display: inline-block;
	}

	input {
		margin: 0;
	}
}

.gfield_checkbox {
	label {
		position: relative;
		padding-left: 30px;
		cursor: pointer;

		&:before {
		  content: "";
		  display: inline-block;
			cursor: pointer;
		  width: 16px;
		  height: 16px;  
		  margin-right: 10px;
		  position: absolute;
		  left: 0;
		  top: 50%;
		  transform: translateY(-50%);
		  background: transparent;
		  border: 2px solid $grey;
		  z-index: 1;
		}
	}

	input[type="checkbox"] {
		display: none;

		&:checked {
			&+ label {
				// &:after {
				//   font-family: 'FontAwesome';
				//   content: "\f00c";
				//   color: $grey;
				//   font-size: 1rem;
				//   text-align: center;
				//   line-height: 15px;
				//   position: absolute;
				//   top: 50%;
				//   left: 3px;
				//   transform: translateY(-50%);
				//   z-index: 2;
				// }

				&:before {
					background: $green;
				}
			}
		}
	}
}

.gfield_radio {
	label {
		position: relative;
		padding-left: 25px;
		cursor: pointer;

		&:before {
		  content: "";
		  display: inline-block;
			cursor: pointer;
		  width: 16px;
		  height: 16px;  
		  margin-right: 5px;
		  position: absolute;
		  left: 0;
		  top: 50%;
		  transform: translateY(-50%);
		  background: transparent;
		  border: 2px solid $grey;
		  border-radius: 50%;
		  z-index: 1;
		}
	}

	input[type="radio"] {
		display: none;

		&:checked {
			&+ label {
				&:after {
				 	content: '';
				  color: black;
				  background: $grey;
				  border-radius: 50%;
				  width: 6px;
				  height: 6px;
				  position: absolute;
				  top: 50%;
				  left: 5px;
				  transform: translateY(-50%);
				  z-index: 2;
				}
			}
		}
	}
}

.ginput_container_select {
	position: relative;
	background: $grey-light;
	width: 450px;
	max-width: 100%;
	cursor: pointer;

	&:before {
		font-family: 'FontAwesome';
		content: '\f0d7';
		color: $grey;
		font-size: 2rem;
		position: absolute;
		top: 50%;
		right: 30px;
		z-index: 0;
		transform: translateY(-50%);
		cursor: pointer;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		right: 70px;
		z-index: 0;
		width: 3px;
		height: 100%;
		background: white;
		cursor: pointer;
	}
}

.gfield_select {
	position: relative;
	z-index: 2;
	appearance: none;
	background: transparent;
	padding-right: 30px;
	cursor: pointer;
}

option {
	color: $grey-dark;
}

.gform_validation_container,
.gfield_required {
	display: none;
}

.validation_message {
	color: $red;
	margin-top: 5px;
}

.radio-group {
	border: 3px solid $yellow;
	padding: 20px;
	// cursor: pointer;
	position: relative;

	&:before {
		font-family: 'FontAwesome';
	  content: "\f00c";
	  color: white;
	  font-size: 2rem;
	  position: absolute;
	  top: 10px;
	  right: 10px;
	  z-index: 2;
	}

	&.toggled {
		background: $yellow;
	}

	.gfield_label {
		font-weight: bold;
	}
}

.validation_error {
	color: $red;
	margin-bottom: 20px;
}

.gfield_error {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="date"],
	input[type="search"],
	input[type="password"],
	input[type="file"],
	input[type="tel"],
	select,
	textarea {
		border: 1px solid $red;
	}
}

img.gform_ajax_spinner {
  display: none!important;
}

.gform_confirmation_message {
	color: white;
  background: $green;
  padding: 20px;
}

// .gform_button {
// 	@media (max-width: $screen-xs-max) {
// 		font-size: 1.3rem!important;
// 	}
// }

.gform_fields {
	padding-left: 0!important;
}






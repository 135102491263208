section {
	padding: 60px 0;

	@media (max-width: $screen-md-max) {
		padding: 55px 0;
	}

	@media (max-width: $screen-sm-max) {
		padding: 50px 0;
	}

	@media (max-width: $screen-xs-max) {
		padding: 40px 0;
	}
}

.content {
	.title {
		margin-top: 30px;
		margin-bottom: 10px;
		line-height: 1.2em;

		@media (max-width: $screen-md-max) {
			margin-top: 25px;
		}

		@media (max-width: $screen-sm-max) {
			margin-top: 20px;
		}

		@media (max-width: $screen-xs-max) {
			margin-top: 0;
		}

		& + .text {
			margin-bottom: 0;
		}
	}
	
	h2 {
		&:first-child {
			margin-top: 0;
		}
	}

	// img {
	// 	margin: 30px 0 0;
	// }

	.content-text {
		margin-bottom: 30px;

		& + .content-frame .frame {
			margin-top: 0;
		}

		ul, ol {
			@media (max-width: $screen-xs-max) {
				padding-left: 20px;
				padding-bottom: 20px;
			}
		}

		img {
			margin: 30px 0;
		}

		.button-align-right {
			text-align: right;

			@media (max-width: $screen-xs-max) {
				text-align: left;
			}
		}
	}

	.content-frame {
		& + .content-text {
			margin-top: 0;
		}
	}
}

.content-image,
section.formats,
.content-images-grid,
.content-video {
	& + .content-text {
		margin: 50px 0;

		@media (max-width: $screen-md-max) {
			margin: 40px 0;
		}

		@media (max-width: $screen-sm-max) {
			margin: 30px 0;
		}

		@media (max-width: $screen-xs-max) {
			margin: 20px 0;
		}
	}
}

.page-image {
	width: 100%;
	height: 550px;
	background-size: cover;
	background-position: center;
}

.content-frame {
	.frame {
		padding: 30px;
		margin: 50px 0;

		@media (max-width: $screen-md-max) {
			padding: 25px;
			margin: 40px 0;
		}

		@media (max-width: $screen-sm-max) {
			padding: 20px;
			margin: 30px 0;
		}

		@media (max-width: $screen-xs-max) {
			padding: 15px;
			margin: 20px 0;
		}

		&.frame-grey {
			background: darken($grey-lighter, 5%);
			// background: $grey-lighter;
		}

		div[class^="col-"] {
			&:last-child {
				@media (max-width: $screen-xs-max) {
					margin-top: 30px;
				}
			}
		}
	}
	
	h2, h3, h4, h5, h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.content-images-grid {
	div[class^="col-"] {
		padding-left: 0;
		padding-right: 0;
	}

	.image-grid {
		width: 100%;
		height: 400px;
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		@media (max-width: $screen-md-max) {
			height: 250px;
		}

		@media (max-width: $screen-sm-max) {
			height: 200px;
		}

		@media (max-width: $screen-xs-max) {
			height: 200px;
		}
	}
}

.content-numbered-images {
	margin: 20px 0;

	@media (max-width: $screen-xs-max) {
		margin-top: 0;
	}

	ol {
		margin: 0;
		padding: 0;
	}

	li {
		display: inline-block;
		list-style: none;
		position: relative;
		border: 1px solid $black-10;

		&:not(:last-child) {
			margin: 5px 5px 10px 0;
		}
	}

	img {
		@media (min-width: $screen-sm-min) {
			width: 200px;
			height: auto;
		}
	}

	.number {
		position: absolute;
		top: 0;
		left: 0;
		background: $yellow;
		color: $color-primary;
		width: 25px;
		height: 25px;
		border-bottom-right-radius: 50%;
		text-align: center;
		font-size: 1.5rem;
		font-family: $font-bold;
	}
}

.warranty {
	background: $grey-lighter;

	h2 {
		margin-top: 0;
	}
}


.not-found {
	margin-bottom: 50px;
}

.image-wrapper,
.plus-wrapper {
	position: relative;
}

.product-plus {
	position: absolute;
	top: 50%;
	right: 12%;
	display: block;
	font-size: 2.5rem;
	color: $color-primary;
	text-transform: uppercase;
	vertical-align: middle;
	z-index: 3;

	@media (max-width: $screen-sm-max) {
		top: 45%;
	}

	.plus {
		opacity: .8;
		display: inline-block;
		margin-right: 20px;
		vertical-align: bottom;
		transition: all 0.3s ease-in-out;
	}

	&:hover {
		text-decoration: none;

		.plus {
			transform: scale(1.2);
			opacity: 1;
		}
	}
}

.product-description {
	width: 100%;
	padding: 30px;
	background: $color-primary;
	position: relative;
	display: none;

	@media (min-width: $screen-md-min) {
		position: absolute;
		bottom: 0;
		left: 0;
		background: rgba($color-primary,0.9);
		z-index: 3;
	}

	@media (max-width: $screen-md-max) {
		padding: 20px;
	}

	@media (max-width: $screen-sm-max) {
		padding: 20px;
	}

	@media (max-width: $screen-xs-max) {
		padding: 15px;
	}

	&:after {
		bottom: 100%;
		right: calc(12% + 15px);
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border-color: rgba(136, 183, 213, 0);
		border-bottom-color: rgba($color-primary,0.9);
		border-width: 20px;
		margin-left: -20px;

		@media (max-width: $screen-sm-max) {
			border-bottom-color: $color-primary;
		}
	}

	p {
		color: white;
	}
}




/* --------------------------------------------------
** BLOG
**------------------------------------------------ */


.page-blog {
	@include section-padding;

	@media (max-width: $screen-xs-max) {
		padding-top: 0;
	}

	.title {
		margin-top: 0;
	}
	
	.blog-post {
		margin: 20px 0;

		div[class^="col-"] {
			&:first-child {
				padding-right: 0;
			}

			&:last-child {
				padding-left: 0;
			}
		}
	}

	.blog-image {
		width: 100%;
		height: 225px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		@media (max-width: $screen-md-max) {
			height: 200px;
		}

		@media (max-width: $screen-sm-max) {
			height: 175px;
		}

		@media (max-width: $screen-xs-max) {
			height: 120px;
		}
	}

	.blog-cat {
		font-family: $font-bold;
		font-size: 1.5rem;
		text-transform: uppercase;
		background: $yellow;
		display: block;
		padding: 5px 5px 5px 20px;
		margin-bottom: 10px;

		@media (max-width: $screen-md-max) {
			font-size: 1.4rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.3rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1rem;
			margin-bottom: 0;
		}

		&.diy {
			background: $color-sec;
			color: white;
		}
	}

	.blog-post-content {
		padding: 10px 20px;

		@media (max-width: $screen-xs-max) {
			padding: 10px;
		}
	}

	.blog-title {
		font-family: $font-bold;
		font-size: 2.5rem;

		@media (max-width: $screen-md-max) {
			font-size: 2.3rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 2.1rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1.7rem;
			display: block;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.blog-excerpt {
		margin-top: 20px;

		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}

	.blog-button {
		color: white;
		background: $color-primary;
		padding: 3px 10px;
		margin-top: 20px;
		display: inline-block;

		&:hover {
			text-decoration: none;
			background: $color-sec;
		}
	}
}


















@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}

@mixin section-padding {
	padding: 60px 0;

	@media (max-width: $screen-md-max) {
		padding: 50px 0;
	}

	@media (max-width: $screen-sm-max) {
		padding: 40px 0;
	}

	@media (max-width: $screen-xs-max) {
		padding: 30px 0;
	}
}
@font-face {
    font-family: 'brandon_textbold';
    src: url('../fonts/brandontext-bold-webfont.woff2') format('woff2'),
         url('../fonts/brandontext-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_textlight';
    src: url('../fonts/brandontext-light-webfont.woff2') format('woff2'),
         url('../fonts/brandontext-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'brandon_textmedium';
    src: url('../fonts/brandontext-medium-webfont.woff2') format('woff2'),
         url('../fonts/brandontext-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'brandon_text_regularregular';
    src: url('../fonts/brandontext-regular-webfont.woff2') format('woff2'),
         url('../fonts/brandontext-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

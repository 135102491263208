.no-gutter > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}

.page-landing {
  overflow: hidden;

  .product-images {
    margin: 0 0 10px;
    position: relative;

    &:before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      background: white;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        height: 5px;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .product-image {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: $screen-md-max) {
      height: 275px;
    }

    @media (max-width: $screen-sm-max) {
      height: 250px;
    }

    @media (max-width: $screen-xs-max) {
      height: 200px;
    }
  }

  .product-image-over {
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    max-width: 300px;
    z-index: 3;

    @media (max-width: $screen-md-max) {
      max-width: 270px;
    }

    @media (max-width: $screen-sm-max) {
      max-width: 240px;
    }

    @media (max-width: $screen-xs-max) {
      max-width: 210px;
    }
  }

  .buy,
  .locator {
    padding: 30px 80px;

    @media (max-width: $screen-md-max) {
      padding: 30px 40px;
    }

    @media (max-width: $screen-sm-max) {
      padding: 30px 60px;
    }

    @media (max-width: $screen-xs-max) {
      padding: 30px 40px;
    }
  }

  .buy {
    background: $color-primary;
  }

  .buy-text {
    color: $yellow;
    font-family: $font-reg;
    font-size: 2.3rem;
    text-transform: uppercase;
    line-height: 1.1em;
    margin-top: 0;
  }

  .buy-link {
    color: $yellow;
    font-family: $font-bold;
    font-size: 1.8rem;
    text-decoration: underline;
    display: inline-block;
    margin-top: 30px;

    &:hover {
      color: white;
    }
  }

  .store {
    display: block;
    margin: 25px 10px;

    @media (max-width: $screen-md-max) {
      margin: 20px 0;
    }

    @media (max-width: $screen-sm-max) {
      margin: 15px 0;
    }

    @media (max-width: $screen-xs-max) {
      margin: 10px 0;
    }
  }

  .store-logo {
    width: 100%;
    height: 100px;
    border: 2px solid white;
    position: relative;
    display: inline-block;
    margin-bottom: 10px;

    &:hover {
      border-color: $yellow;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      width: auto;
      height: auto;
      max-width: 150px;
      max-height: 150px;

      @media (max-width: $screen-xs-max) {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }

  .store-link {
    display: block;
    color: white;
    font-family: $font-bold;
    font-size: 1.6rem;
    text-transform: uppercase;

    @media (max-width: $screen-md-max) {
      font-size: 1.5rem;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 1.4rem;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 1.3rem;
    }

    &:hover {
      text-decoration: none;
      color: $yellow;
    }
  }

  .store-text {
    display: block;
    font-size: clamp( 1rem, 1.1vw, 1.2rem );
    color: #FFF;
    padding-top: 5px;
  }

  .col-xs-5ths,
  .col-sm-5ths,
  .col-md-5ths,
  .col-lg-5ths {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
  }

  .col-xs-5ths {
      width: 20%;
      float: left;
  }

  @media (min-width: 768px) {
      .col-sm-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 992px) {
      .col-md-5ths {
          width: 20%;
          float: left;
      }
  }

  @media (min-width: 1200px) {
      .col-lg-5ths {
          width: 20%;
          float: left;
      }
  }

  .landing-content {
    background: $color-primary;
    position: relative;

    &:before {
      @media (min-width: 992px) {
        content: '';
        width: 10px;
        height: 100%;
        background: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .col-md-6 {
      &:first-child {
        padding-right: 5px;
      }

      &:last-child {
        padding-left: 5px;
      }
    }
  }

  .landing-title {
    color: white;
    font-family: $font-medium;
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 1em 0;

    @media (max-width: $screen-md-max) {
      font-size: 2.3rem;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 2.1rem;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 1.9rem;
    }
  }

  .locator {
    background: $color-sec;
  }

  .details {
    @include section-padding;

    // @media (max-width: $screen-xs-max) {
    //   padding: 0;
    // }

    // .container {
    //   @media (max-width: $screen-xs-max) {
    //     padding: 0;
    //   }
    // }
  }

  .detail-content {
    display: block;
    transition: all 0.2s ease-in-out;

    &:hover {
      text-decoration: none;

      .detail-image {
        box-shadow: 0 0 15px rgba(0,0,0,.1);
      }

      .detail-link {
        text-decoration: none;
        background: $color-sec;
      }
    }
  }

  .detail-image {
    display: block;
    width: 100%;
    height: 350px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.2s ease-in-out;

    @media (max-width: $screen-md-max) {
      height: 300px;
    }

    @media (max-width: $screen-sm-max) {
      height: 250px;
    }

    @media (max-width: $screen-xs-max) {
      height: 200px;
    }


  }

  .detail-link {
    display: block;
    background: $color-primary;
    padding: 5px 10px;
    text-align: center;
    color: white;
    font-family: $font-bold;
    font-size: 2rem;
    text-transform: uppercase;
    margin-top: 5px;
    transition: all 0.2s ease-in-out;

    @media (max-width: $screen-md-max) {
      font-size: 1.8rem;
    }

    @media (max-width: $screen-sm-max) {
      font-size: 1.6rem;
    }

    @media (max-width: $screen-xs-max) {
      font-size: 1.4rem;
    }
  }
}

















header {
	width: 100%;
	background: url('../images/BG-gradient.jpg') no-repeat;
	background-position: center;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5;

	.container {
		position: relative;
	}

	.nav-wrapper {
		position: relative;

		@media (max-width: $screen-xs-max) {
			background: $color-primary;
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	.brand {
		background: $blue;
		padding: 15px 30px;
		display: inline-block;

		@media (max-width: $screen-md-max) {
			padding: 15px 25px;
		}

		@media (max-width: $screen-sm-max) {
			padding: 15px 20px;
		}

		@media (max-width: $screen-xs-max) {
			padding: 15px;
		}

		img {
			width: 140px;

			@media (max-width: $screen-sm-max) {
				width: 100px;
			}
		}
	}

	.brand-slogan {
		color: $yellow;
		font-size: 2rem;
		line-height: 1.4em;
		letter-spacing: 3px;
		text-transform: uppercase;
		display: inline-block;
		font-family: $font-bold;
		vertical-align: top;
		margin-left: 20px;

		@media (max-width: $screen-md-max) {
			font-size: 1.6rem;
			letter-spacing: 2px;
			margin-left: 15px;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.2rem;
			letter-spacing: 1px;
			margin-left: 10px;
		}

		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}
}


.home {
	header {
		background-image: none;
		position: absolute;
		transition: all 0.2s ease-in-out;

		&.headerHeight {
			top: -157px;
		}

		&.fixed {
			position: fixed;
			top: 0;
			background-image: url('../images/BG-gradient.jpg');
		}

		&:not(.fixed) {
			.brand {
				padding: 30px;

				@media (max-width: $screen-md-max) {
					padding: 25px;
				}

				@media (max-width: $screen-sm-max) {
					padding: 20px;
				}

				@media (max-width: $screen-xs-max) {
					padding: 15px;
				}

				img {
					width: 150px;

					// @media (max-width: $screen-md-max) {
					// 	width: 125px;
					// }

					@media (max-width: $screen-sm-max) {
						width: 100px;
					}

					@media (max-width: $screen-xs-max) {
						width: 100px;
					}
				}
			}
		}
	}
}








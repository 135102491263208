.home {
	.text-small {
		font-size: 1.3rem;
	}

	.subtitle {
		@media (max-width: $screen-xs-max) {
			margin-top: 0;
		}
	}
}

.showcase {
	padding-top: 160px;
	padding-bottom: 10px;
	background: url('../images/BG-gradient.jpg') no-repeat;
	background-position: center;
	background-size: cover;
	overflow: hidden;

	@media (max-width: $screen-md-max) {
		padding-bottom: 0;
	}

	@media (max-width: $screen-sm-max) {
		padding-top: 130px;
		padding-bottom: 50px;
	}

	@media (max-width: $screen-xs-max) {
		padding-top: 100px;
		padding-bottom: 30px;
	}

	.container {
		height: 500px;
		position: relative;

		@media (max-width: $screen-sm-max) {
			height: 340px;
		}

		@media (max-width: $screen-xs-max) {
			height: 500px;
		}
	}

	.showcase-content {
		@media (min-width: $screen-sm-min) {
			position: relative;
			z-index: 2;
			max-width: 50%;
		}
	}

	.title {
		font-size: 5.5rem;
		line-height: 1em;
		font-family: $font-light;
		color: $blue;
		text-transform: uppercase;
		margin: 80px 0 0;

		@media (max-width: $screen-md-max) {
			font-size: 4.5rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 5rem;
			margin-top: 0;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 3rem;
			font-family: $font-reg;
			max-width: 100%;
		}

		br {
			@media (max-width: $screen-xs-max) {
				display: none;
			}
		}
	}

	.subtitle {
		font-size: 2.5rem;
		letter-spacing: 1px;
		margin-top: 20px;

		@media (max-width: $screen-md-max) {
			font-size: 1.8rem;
			margin-top: 20px;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 1.7rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 1.5rem;
			font-family: $font-bold;
			max-width: 100%;
		}
	}

	.buttons-wrap {
		max-width: 85%;
		margin: 40px 0 0;
		display: flex;

		@media (max-width: $screen-md-max) {
			max-width: 100%;
		}

		@media (max-width: $screen-sm-max) {
			margin: 30px 0 0;
		}

		@media (max-width: $screen-xs-max) {
			position: absolute;
			bottom: 0;
			left: 50%;
			z-index: 5;
			transform: translateX(-50%);
			width: 80%;
			text-align: center;
		}

		.button {
			width: 49%;
			text-transform: initial;
			padding: 1em;

			&:first-child {
				margin-right: 2%;
			}

			&:last-child {
				background: white;
				border-color: white;

				&:hover {
					background: $color-primary;
					border-color: $color-primary;
				}
			}
		}

		// .video-link {
		// 	color: white;
		// 	font-size: 3.5rem;
		// 	line-height: 1.1em;
		// 	display: inline-block;
		// 	text-transform: uppercase;
		// 	border: 2px solid white;
		// 	padding: 15px;
		// 	vertical-align: middle;

		// 	@media (max-width: $screen-md-max) {
		// 		font-size: 3rem;
		// 		padding: 13px;
		// 	}

		// 	@media (max-width: $screen-sm-max) {
		// 		font-size: 2.5rem;
		// 		padding: 11px;
		// 	}

		// 	@media (max-width: $screen-xs-max) {
		// 		font-size: 2rem;
		// 		padding: 9px;
		// 	}

		// 	&:hover {
		// 		text-decoration: none;
		// 		background: white;
		// 		color: $color-primary;
		// 	}

		// 	&:before {
		// 		content: '\f16a';
		// 		font-family: 'FontAwesome';
		// 		font-size: 7rem;
		// 		display: inline-block;
		// 		color: #b31217;
		// 		margin-right: 20px;
		// 		vertical-align: middle;

		// 		@media (max-width: $screen-md-max) {
		// 			font-size: 6rem;
		// 		}

		// 		@media (max-width: $screen-sm-max) {
		// 			font-size: 5rem;
		// 		}

		// 		@media (max-width: $screen-xs-max) {
		// 			font-size: 4rem;
		// 		}
		// 	}
		// }
	}

	.installation {
		margin-top: 20px;

		@media (max-width: $screen-xs-max) {
			display: none;
		}

		.fa {
			color: $blue;
			font-size: 4rem;
			display: inline-block;
			vertical-align: middle;
		}

		.installation-text {
			color: $blue;
			font-size: 2rem;
			vertical-align: middle;
			display: inline-block;
			text-transform: uppercase;
			margin: 0;
			padding-left: 10px;

			@media (max-width: $screen-sm-max) {
				font-size: 1.4rem;
			}
		}

		.trademark {
			font-size: 1rem;
			margin-top: 20px;
			text-transform: initial;
		}
	}

	.featured-product {
		width: 1140px;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;

		@media (max-width: $screen-sm-max) {
			width: 900px;
		  top: 0;
		  right: -165px;
		}

		@media (max-width: $screen-xs-max) {;
			width: 600px;
			height: 337px;
		  top: 190px;
		  right: initial;
		  left: 15%;
  		transform: translateX(-50%);
		}

		// img {
		// 	@media (max-width: $screen-xs-max) {
		// 		width: 300px;
		// 	}
		// }
	}

	// .front-bracket {
	// 	max-height: 500px;
	//   position: absolute;
	//   top: -7px;
	//   right: 132px;
	// 	z-index: 3;
	// 	transition: all 0.2s linear;
	// 	transform-origin: bottom center;
	// 	display: none;

	// 	&.transform {
	// 		animation: frontBracket 1.5s;
 //    	animation-fill-mode: both;
	// 	}

	// 	@keyframes frontBracket {
	//     0% {
	//       transform: skew(0);
	//     }
	//     10% {
	//     	transform: skew(10deg);
	//     }
	//     90% {
	//     	transform: skew(10deg);
	//     }
	//     100% {
	//     	transform: skew(0);
	//       left: 125px;
 //        top: 15px;
 //        max-height: 430px;
	//     }
	// 	}
	// }

	// .back-bracket {
	// 	max-height: 391px;
	// 	position: absolute;
	// 	top: 40px;
	// 	right: 0;
	// 	z-index: 2;
	// 	display: none;
	// 	// transition: all 0.2s ease-in-out;
	// }

	.full-bracket {
		position: absolute;
		top: -93px;
		right: -114px;
		z-index: 1;

		// @media (max-width: $screen-md-max) {
		// 	font-size: 3.5rem;
		// }

		// @media (max-width: $screen-sm-max) {
		// 	right: -250px;
		// }

		// @media (max-width: $screen-xs-max) {
		// 	right: -330px;
		// }
	}

	.link {
		position: absolute;
		display: block;
		font-size: 2.1rem;
		color: $color-primary;
		text-transform: uppercase;
		vertical-align: middle;
		z-index: 3;

		.plus {
			width: 30px;
			opacity: .8;
			display: inline-block;
			margin-right: 15px;
			vertical-align: bottom;
			transition: all 0.3s ease-in-out;

			@media (max-width: $screen-sm-max) {
				margin-right: 0;
			}

			// @media (min-width: $screen-md-min) {
			// 	background: $color-primary;
			// 	width: 20px;
			// 	height: 20px;
			// 	margin-bottom: 6px;
			// }
		}

		// &:before {
		// 	content: "";
		// 	background: url('../images/Plus.svg') no-repeat;
		// 	background-size: cover;
		// 	width: 30px;
		// 	height: 30px;
		// 	opacity: .8;
		// 	display: inline-block;
		// 	margin-right: 20px;
		// 	vertical-align: bottom;
		// 	transition: all 0.3s ease-in-out;
		// }

		&:hover {
			text-decoration: none;

			.plus {
				transform: scale(1.2);
				opacity: 1;
			}
		}

		&.link-1 {
			top: 35px;
			left: 845px;

			// @media (max-width: $screen-md-max) {
			// 	left: 635px;
			// }

			@media (max-width: $screen-sm-max) {
				top: 5px;
  			left: 695px;
			}

			@media (max-width: $screen-xs-max) {
				top: -30px;
  			right: 70px;
				left: initial;
			}
		}

		&.link-2 {
			top: 160px;
			left: 865px;

			// @media (max-width: $screen-md-max) {
			// 	left: 655px;
			// }

			@media (max-width: $screen-sm-max) {
				top: 100px;
				left: 715px;
			}

			@media (max-width: $screen-xs-max) {
				top: 40px;
				left: initial;
				right: 50px;
			}
		}

		&.link-3 {
			top: 320px;
			left: 855px;

			// @media (max-width: $screen-md-max) {
			// 	left: 645px;
			// }

			@media (max-width: $screen-sm-max) {
				top: 210px;
  			left: 705px;
			}

			@media (max-width: $screen-xs-max) {
				top: 110px;
			  left: initial;
			  right: 60px;
			}
		}

		&.link-4 {
			top: 410px;
			left: 670px;

			@media (max-width: $screen-sm-max) {
				top: 300px;
  			left: 550px;
			}

			@media (max-width: $screen-xs-max) {
				top: 160px;
  			right: 160px;
  			left: initial;
			}
		}

		&.link-inverted {
			&:before {
				display: none;
			}

			.plus {
				margin-right: 0;
				margin-left: 15px;

				@media (max-width: $screen-sm-max) {
					margin-left: 0;
				}
			}

			// &:after {
			// 	content: "";
			// 	background: url('../images/Plus.svg') no-repeat;
			// 	background-size: cover;
			// 	width: 30px;
			// 	height: 30px;
			// 	opacity: .8;
			// 	display: inline-block;
			// 	margin-left: 20px;
			// 	vertical-align: bottom;
			// 	transition: all 0.3s ease-in-out;
			// }

			&:hover {
				text-decoration: none;

				&:after {
					transform: rotate(180deg) scale(1.1);
					opacity: 1;
				}
			}
		}

		.link-text {
			@media (max-width: $screen-sm-max) {
				display: none;
			}
		}
	}
}

.what {
	background: $blue;
	width: 100%;
	padding: 0;
	position: relative;

	.subtitle,
	.text,
	p,
	a,
	li {
		color: white;
	}

	ul {
		margin: 0;
		padding: 0;

		li {
			list-style: none;
			border-bottom: 1px solid rgba(255,255,255,0.35);
			padding-bottom: 10px;
			margin-bottom: 10px;

			&:last-child {
				border-bottom: 0;
			}

			a {
				font-family: $font-bold;
			}
		}
	}

	.what-text {
		padding: 50px 50px 50px 0;

		@media (max-width: $screen-md-max) {
			padding: 40px 40px 40px 0;
		}

		@media (max-width: $screen-sm-max) {
			padding: 30px 30px 30px 0;
		}

		@media (max-width: $screen-xs-max) {
			padding: 40px 0;
		}
	}

	.what-image {
		background: white;
		position: relative;

		@media (min-width: $screen-md-min) {
			position: absolute;
			top: 0;
			right: 0;
			width: 50%;
			height: 100%;
		}

		img {
			min-height: 100%;
		  max-height: 100%;
		  max-width: initial;
		  position: absolute;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%,-50%);
		}
	}

	.carousel {
		height: 100%;
  	overflow: hidden;
	}

	.carousel-inner > .item {
		overflow: hidden;
  // 	height: 884px;

  // 	@media (max-width: $screen-md-max) {
		// 	height: 884px;
		// }

		// @media (max-width: $screen-sm-max) {
		// 	height: 500px;
		// }

		// @media (max-width: $screen-xs-max) {
		// 	height: 300px;
		// }
	}

	.button {
		margin-bottom: 0;
	}
}

.carousel-indicators {
	z-index: 3;
}

.carousel-indicators li {
	background: white;

	&.active {
		background: $grey;
		border-color: $grey;
	}
}

.usage {
	padding-top: 10px;

	.carousel-indicators {
		bottom: -5px;
	}

	.carousel-control {
		background: none;
	}

	.carousel-control .glyphicon-chevron-right,
	.carousel-control .glyphicon-chevron-left {
		font-size: 25px;
	}

	.usage-image {
		// width: 100%;
		// height: 300px;
		// display: block;
		margin: 30px 0;
		// transition: all 0.2s ease-in-out;
		// overflow: hidden;

		// @media (max-width: $screen-md-max) {
		// 	height: 250px;
		// }

		// @media (max-width: $screen-sm-max) {
		// 	height: 200px;
		// }

		@media (max-width: $screen-xs-max) {
			margin: 30px -15px;
		}

		// .item {
		// 	position: relative;
		// }

		// img {
		//   min-height: 100%;
		//   max-height: 100%;
		//   max-width: initial;
		//   position: absolute;
		//   top: 50%;
		//   left: 50%;
		//   transform: translate(-50%,-50%);
		//   transition: all 0.2s ease-in-out;
		// }

		// .usage-image-2 {
		// 	opacity: 0;
		// }

		// &:hover {
		// 	.usage-image-1 {
		// 		opacity: 0;
		// 	}

		// 	.usage-image-2 {
		// 		opacity: 1;
		// 	}
		// }
	}

	.usage-desc {
		margin-top: 30px;

		div[class^="col-"] {
			&:first-child {
				@media (max-width: $screen-md-max) {
					padding-right: 10px;
				}

				@media (max-width: $screen-sm-max) {
					padding-right: 5px;
				}

				@media (max-width: $screen-xs-max) {
					padding-right: 5px;
				}
			}

			&:last-child {
				@media (max-width: $screen-md-max) {
					padding-left: 10px;
				}

				@media (max-width: $screen-sm-max) {
					padding-left: 5px;
				}

				@media (max-width: $screen-xs-max) {
					padding-left: 5px;
				}
			}
		}

		img {
			@media (max-width: $screen-xs-max) {
				width: 100%;
				max-width: 80px;
			}
		}
	}

	.usage-title {
		text-transform: uppercase;
		margin-top: 0;
	}
}

.formats {
	background: $yellow;

	.subtitle {
		margin-top: 0;
	}

	.button {
		margin: 0;
	}

	.formats-details {
		text-align: center;
	}

	.image {
		margin: 60px 0 40px;

		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}

	.detail-size {
		color: $blue;
		font-size: 5rem;
		text-align: center;
		margin: 0;
		font-family: $font-reg;

		@media (max-width: $screen-md-max) {
			font-size: 4rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 3rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 2.5rem;
		}
	}

	.to {
		font-size: 3rem;

		@media (max-width: $screen-md-max) {
			font-size: 2.7rem;
		}

		@media (max-width: $screen-sm-max) {
			font-size: 2.4rem;
		}

		@media (max-width: $screen-xs-max) {
			font-size: 2.1rem;
		}
	}

	.detail-title {
		text-transform: uppercase;
		margin: 0;

		@media (max-width: $screen-xs-max)  {
			margin-top: 20px;
			border-top: 1px solid $black-10;
			padding-top: 20px;
		}
	}

	.detail-cm {
		margin: 0;
	}

	.button {
		@media (max-width: $screen-xs-max) {
			margin-top: 30px;
		}
	}
}

.how {
	background: $yellow;

	.subtitle {
		display: inline-block;
	}

	.button {
		margin: 0;
	}

	.text {
		margin-bottom: 20px;
	}
}


.find {
	background: $green;

	.subtitle,
	.text-white p {
		color: white;

		a {
			color: white;
			font-family: $font-bold;
		}
	}

	.locator {
		margin: 50px 0;
	}
}


.details {
	@media (max-width: $screen-sm-max) {
		text-align: center;
	}

	.small-title {
		font-family: $font-bold;
		text-transform: uppercase;
		margin: 0;
	}

	.text-small,
	.text-small p {
		margin: 20px 0 0;
		font-size: 1.3rem;
	}

	// .details-header {

	// }

	.image {
		@media (max-width: $screen-sm-max) {
			margin-bottom: 10px;
		}
	}

	.text {
		margin-top: 15px;
	}

	& > .container > .row > div[class^="col-"] {
		&:nth-child(even) {

			@media (max-width: $screen-xs-max) {
				margin: 20px 0;
			}
		}
	}
}


.blog-cats {

  .blog-cats-items {
    display: flex;
    flex-wrap: wrap;
  }

	.blog-cats-item {
    position: relative;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding: clamp( 2rem, 3.5vw, 5rem );
    width: 50%;

    &:nth-of-type(odd) {
      .blog-cats-item-content {
        align-items: flex-end;
      }

      .blog-cats-item-title {
        text-align: right;
      }
    }
	}

	.blog-cats-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
		width: 100%;
	}

	.blog-cats-item-title {
		img {
			@media (max-width: $screen-xs-max) { width: 60%; }
		}
	}

	.blog-cats-item-icon {
		margin: clamp( 1.5rem, 2.75vw, 4rem ) 0;
		@media (max-width: $screen-xs-max) { text-align: left; }

		img { max-width: clamp( 6rem, 9vw, 12rem ); }
	}

	.button {
		margin: 0;
		font-family: $font-reg;
		font-size: clamp( 1.2rem, 1.85vw, 2.5rem );
    padding: .5em 1em;
	}
}























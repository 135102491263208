button,
a.button,
.button,
.btn,
input[type="submit"],
.post-button a,
.gform_button {
	padding: 12px 25px;
	background: transparent;
	color: $color-primary;
	font-size: 1.6rem;
	font-family: $font-bold;
	display: inline-block;
	text-align: center;
	margin: 30px auto 20px;
	transition: all 0.2s ease-in-out;
	border: 2px solid $color-primary;
	text-decoration: none;
	text-transform: uppercase;

	@media (max-width: $screen-md-max) {
		font-size: 1.5rem;
	}

	@media (max-width: $screen-sm-max) {
		font-size: 1.4rem;
	}

	@media (max-width: $screen-xs-max) {
		font-size: 1.3rem;
	}

	&:hover {
		color: white;
		background: $color-primary;
		text-decoration: none;
	}

	&.button-white {
		color: white;
		border-color: white;

		&:hover {
			color: $color-primary;
			background: white;
		}
	}

	&.filters-toggle {
		font-size: 1.7rem;
		line-height: 1.2em;
		border-width: 1px;
		padding: 5px 30px 5px 5px;
		position: relative;
		margin: 0 0 10px 0;

		@media (min-width: $screen-sm-min) {
			display: none;
		}

		&.toggled {
			.fa {
				transform: translateY(-50%) rotate(180deg);
			}
		}

		.fa {
			font-size: 2.8rem;
			line-height: 1rem;
			position: absolute;
			top: 50%;
			right: 5px;
			transform: translateY(-50%);
			transition: 0.2s all ease-in-out;
		}
	}
}





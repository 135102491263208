.locator {
	#wpsl-wrap {
		overflow: initial;

		#wpsl-search-wrap {
			float: none !important;

			.container-fluid {
				float: none !important;
				margin-right: 0;
			}

			.row {
				float: none !important;
				margin-right: -15px;
			}

			.bootstrap-col {
				font-size: 0;
				margin: 0;

				@media (max-width: $screen-sm-max) {
					float: none;
				}

				&:last-child {
					@media (max-width: $screen-sm-max) {
						margin-top: 1%;
					}
				}
			}
		}

		.wpsl-input {
			width: 100%;
			display: block;
			margin: 0;
			float: none;
		}

		.wpsl-select-wrap {
			margin-bottom: 0;
			width: 48%;
			margin-right: 2%;
			font-size: 1.8rem;

			// @media (max-width: $screen-md-max) {
			// 	font-size: 1.5rem;
			// }

			@media (max-width: $screen-xs-max) {
				width: 49.5%;
				margin: 0 1% 0 0;
			}

			#wpsl-radius {
				width: 100%;
				margin-right: 0;
				display: block;
			}
		}

		#wpsl-category {
			margin: 0;
			width: 48%;
			clear: none !important;
			font-size: 1.8rem;

			// @media (max-width: $screen-md-max) {
			// 	font-size: 1.5rem;
			// }

			@media (max-width: $screen-xs-max) {
				width: 49.5%;
				margin: 0;
			}

			&:after {
				clear: none !important;
			}
		}

		.wpsl-search-btn-wrap {
			width: 100%;
			margin: 30px 0;
			display: flex;
			justify-content: center;
			text-align: center;
			// width: 39%;
			// margin: 0 0 0 1%;

			// @media (max-width: $screen-xs-max) {
			// 	margin-top: 15px;
	  // 		clear: both;
	  // 		float: none;
			// }
		}



		.wpsl-search {
			background: transparent;
	  	margin-bottom: 6px;
	  	padding: 0;
	  	width: 100%;

	  	// @media (min-width: $screen-sm-min) {
	  	// 	width: 66.5%;
	  	// 	float: right;
	  	// }

	  	// @media (max-width: $screen-xs-max) {
	  	// 	padding: 0;
	  	// }
		}

		form label {
			border-bottom: 0;
		}

		#wpsl-search-input {
			background: $black-10;
			font-size: 1.6rem !important;
			// width: 652.5px;
			height: 50px;
			border-radius: 0;
			border: 0;
			color: white;

			@media (max-width: $screen-xs-max) {
				width: 100%!important;
			}

			&:focus {
				box-shadow: none;
			}

			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			  color: white;
			}
			::-moz-placeholder { /* Firefox 19+ */
			  color: white;
			}
			:-ms-input-placeholder { /* IE 10+ */
			  color: white;
			}
			:-moz-placeholder { /* Firefox 18- */
			  color: white;
			}
		}

		#wpsl-search-btn {
			// width: 100%;
			padding: 12px 25px;
			background: transparent;
			color: white;
			font-size: 1.6rem;
			font-family: $font-bold;
			display: inline-block;
			text-align: center;
			transition: all 0.2s ease-in-out;
			border: 2px solid white;
			text-decoration: none;
			text-transform: uppercase!important;
			margin: 0;
			border-radius: 0;

			&:hover {
				color: $green;
				background: white;
			}
		}

		#wpsl-search-input,
		input,
		.wpsl-no-filters #wpsl-search-wrap .wpsl-input {
			@media (max-width: $screen-xs-max) {
				width: 100%;
			}
		}



		

		.wpsl-input label, #wpsl-radius label, #wpsl-category label {
			width: initial;

			@media (max-width: $screen-xs-max) {
				margin-top: 0;
			}
		}

		// #wpsl-search-wrap .wpsl-input {
		// 	width: calc(80% - 210px);

		// 	@media (max-width: $screen-sm-max) {
		// 		width: calc(70% - 210px);
		// 	}

		// 	@media (max-width: $screen-xs-max) {
		// 		width: 100%;
		// 	}
		// }

		#wpsl-search-wrap .wpsl-input label {
			display: none;
		}

		#wpsl-search-wrap .wpsl-input input {
			width: 100%;
		}

		.wpsl-dropdown {
			width: 100%;
			background: $black-10;
			color: $color-primary;
			border: 0;
			border-radius: 0;
			height: 50px;
			padding-top: 3px;

			@media (max-width: $screen-xs-max) {
				width: 100%!important;
			}

			&:hover {
				box-shadow: none;
			}

			&.wpsl-active .wpsl-selected-item:after {
				border-top-color: transparent;
			}
		}

		#wpsl-radius-dropdown {
			overflow: hidden;
		}

		.wpsl-selected-item {
			color: white;
			overflow: hidden;
			max-height: 35px;

			&:after {
				border-top-color: white;
			}


		}
	}


	#wpsl-result-list {
		width: 100%;
		margin: 0;

		#wpsl-stores {
			overflow: visible;
			height: auto!important;
		}

		li {
			list-style-type: none;
			border-bottom: 1px solid $black-10;
			margin: 10px 1%;
			padding: 0 0 20px 0;
			width: 48%;
			float: left;
			min-height: 220px;

			@media (max-width: $screen-md-max) {
				width: 46%;
				min-height: 240px;
			}

			@media (max-width: $screen-sm-max) {
				width: 44%;
				min-height: 175px;
			}

			@media (max-width: $screen-xs-max) {
				width: 100%;
				float: none;
				min-height: initial;
			}
		}
	}

	.wpsl-store-location,
	.wpsl-direction-wrap {
		color: white;

		p, li, a {
			color: white;
			font-size: 1.5rem;

			@media (max-width: $screen-md-max) {
				font-size: 1.4rem;
			}

			@media (max-width: $screen-sm-max) {
				font-size: 1.3rem;
			}

			@media (max-width: $screen-xs-max) {
				font-size: 1.2rem;
			}
		}

		strong {
			color: white;
			font-size: 1.6rem;

			@media (max-width: $screen-md-max) {
				font-size: 1.5rem;
			}

			@media (max-width: $screen-sm-max) {
				font-size: 1.4rem;
			}

			@media (max-width: $screen-xs-max) {
				font-size: 1.3rem;
			}
		}
	}

	#wpsl-gmap {
		width: 100%;
		height: 550px;
		float: initial;

		@media (max-width: $screen-md-max) {
			height: 500px;
		}

		@media (max-width: $screen-sm-max) {
			height: 450px;
		}

		@media (max-width: $screen-xs-max) {
			height: 300px;
		}
	}

	#wpsl-radius,
	#wpsl-category {
		clear: initial;

		label {
			display: none;
		}
	}





}
aside {
	@media (max-width: $screen-xs-max) {
		display: none;
	}

	.blog-cat-title {
		font-family: $font-bold;
		margin-top: 15px;

		@media (max-width: $screen-xs-max) {
			display: none;
		}
	}

	.nav-filters {
		margin-top: 30px;

		li {
			list-style: none;
			margin: 10px 0;

			&:not(:last-child) {
				@media (max-width: $screen-xs-max) {
					padding-bottom: 10px;
					border-bottom: 1px solid rgba($color-primary, .1);
				}
			}

			&.active {
				a {
					font-family: $font-bold;
					color: $color-sec;
				}
			}
		}

		a {
			display: block;
			
			&:hover {
				text-decoration: none;
				color: $color-sec;
			}
		}
	}
}